@font-face {
  font-family: "Nebula Regular";
  src: local("Nebula-Regular"),
    url("./Assets/fonts/Nebula-Regular.otf") format("opentype");
}
@font-face {
  font-family: "nasalization-rg";
  src: local("nasalization-rg"),
    url("./Assets/fonts/nasalization-rg.otf") format("opentype");
}
:root{
  --themeclr: #00dffc;
  --white : #fff;
}
.listed_slick{
  padding: 0 10px;
}
.blogdetailimg{
  width: 100%;
  max-width: 1000px;
}
.newsheight{
  min-height: 100vh;
}
.linked_hset{
  min-height: 34px;
}
.socialicons{
  /* background-color: var(--themeclr); */
  border: 1px solid #076474;
  background-color: #076474;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50px;
}
.socialicons:hover{
  border: 1px solid #076474;
  background-color: #076474;
  color: var(--white);
}
.socialicons svg{
  font-size: 20px;
}
.teamlisted a{
  text-decoration: none;
}
.owl-stage-outer a{
  text-decoration: none;
}
.footlogo{
  max-width: 200px;
  width: 100%;
}
.navbar {
  padding-bottom: 0rem !important; 
}
.arrow_left{
  left: -190px !important;
}
.arrow_right{
  right: -190px !important;
}
.carousel_width div[style="transform: translateY(-50%) translateX(-25%) scale(0.5); left: 0%; opacity: 0.25; z-index: 0;"] {
  transform: translateY(-50%) translateX(-25%) scale(0.8) !important;
  transition: 0.5s !important;
  filter: grayscale(1);
  
}
.carousel_width div[style="transform: translateY(-50%) translateX(-75%) scale(0.5); left: 100%; opacity: 0.25; z-index: 0;"]{
  transform: translateY(-50%) translateX(-75%) scale(0.8) !important;
  transition: 0.5s !important;
  filter: grayscale(1);
}
.des_para a{
   color: var(--themeclr) !important;
}

.innerpagetop{
  padding-top: 130px;
}





/* .gallery::after,.blog::after, .media::after */
.about_galfi::after{
  content: "";
  background: #00363e;
  position: absolute;
  z-index: -1;
  top: 0%;
  left: 0%;
  filter: blur(70px);
  width: 100%;
  height: 100%;
  opacity: 0.1;
}
.transition{
  transition: 0.5s !important;
}
.transition:hover{
  transition: 0.5s !important;
}
.alltext{
position: absolute;
width: 200px;
min-width: 200px;
color: #ffff;
font-size: 13px;
transition: 0.5s;
text-transform: uppercase;
}
.alltext.active, .alltext:hover{
  color: var(--themeclr) !important;
  transition: 0.5s;
}
.point0{
left: -18%;
top: 16%;
}
.point1 {
  left: 14%;
  top: -5%;
}
.point2 {
    left: 77%;
    top: -8%;
}
.point3 {
  left: 99%;
  top: 12%;
}
.point4 {
  right: -50%;
  bottom: 38%;
}
.point5 {
  bottom: 4%;
  left: 90%;
}
  .point6 {
    bottom: -7%;
    left: 14%;
}
.point7 {
  bottom: 14%;
  left: -7%;
}
/* .heading .dropdown-menu a img{
  margin-bottom: 15px;
}
.heading .dropdown-menu a span{
  margin-bottom: 15px;
} */
/* common css */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
  background: var(--themeclr); 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--themeclr); 
}
/* end of common css */
.carousel_width{
  width: 70%;
   height: 500px;
   margin: 0 auto;
    position: relative;
}
.no_data{
  color: #707070;
  text-align: center;
}
.blog_height::-webkit-scrollbar-track,.h_under::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #111111;
}
.blog_height::-webkit-scrollbar,.h_under::-webkit-scrollbar
{
	width: 9px;
	background-color: transparent;
}
.blog_height::-webkit-scrollbar-thumb, .h_under::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #111111;
}
body{
  font-family: "nasalization-rg" !important;
}
.lh{
  line-height: 26px;
}
.nebula{
  font-family: "Nebula Regular" !important;
}
.header{
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 77;
}
.header.scrolled {
  /* background-color: #000; */
   /* background-color: #025463;  */
   background-image: linear-gradient(to right, #023e49f5, #077083);
}
.custom_contain{
  max-width: 90%;
}
.h_set{
  /* height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; */
  min-height: unset;
}
body{
  background-color: #000;
}
.roadmapsection{
  background: url("./Assets/galaxyback.webp") no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  background-color: #005a691a;
  position: relative;
}
.roadmapsection::after{
   content: "";
   background: #0770831c;
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 220px;
   filter: blur(50px);
   z-index: -1;
}
.roadmapsection.builtons::after{
   background: transparent;
}
.bot_color::after{
  content: "";
  background: #000000cf;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 60px;
  filter: blur(50px);
  z-index: -1;
}
.phaseimg{
  background: #00070a;
}
.roadmap_section{
  /* max-width: 50rem; */
  margin: auto;
  padding-left: 0;
  position: relative;
  margin-left: 42px;
}
.roadmap_section::after{
  background: #077083;
  content: "";
  height: 100%;
  left: 15px;
  position: absolute;
  top: 0;
  width: 2px;
  z-index: -1;
}
.pos_list{
  position: relative;
  align-items: center;
}
.pos_list::after{
  background: #077083;
  content: "";
  height: 170px;
  left: 57px;
  position: absolute;
  top: 17px;
  width: 2px;
  z-index: -1;
}
.overflow_wraps {
  overflow-wrap: anywhere;
}
.roadmap_section li{
   display: flex;
   gap: 35px;
   align-items: start;
   color: #fff;
   margin-bottom: 18px;
}
.roadmap_section li span{
  padding-bottom: 5px;
   border-bottom: 1px solid #077083;
}
.play_galfi{
  background: url("./Assets/playgalfi_banner.webp") no-repeat;
  /* background-size: 100% 100%; */
  background-size: cover;
  width: 100%;
  background-position: center;
}
.heading a{
  color: #fff !important;
  text-decoration: none !important;
  /* padding: 26px 34px 26px 34px; */
  padding: 46px 38px 26px 30px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.5s;
  width: 100%;

  
}
.heading a:hover, .heading a.active{
  background: url("./Assets/hovers.webp") no-repeat;
  width: 100%;
  background-size: 100% 100%;
  background-position: center;
  transition: 0.5s;
}
.playbtn{
  background: url("./Assets/ban_btn.webp") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  width: 350px;
  min-width: 350px;
  padding: 16px 0px;
  font-style: italic;
  font-weight: 500;
}
.playbtn:hover{
  color: var(--themeclr) !important;
}
.pos{
  overflow: hidden;
}
.galfi_p, .pos{
  position: relative;
  z-index: 3;
}
.galfi_p::before{
  content: "";
  background: url("./Assets/ptop.webp") no-repeat;
  width: 41px;
  height: 41px;
  position: absolute;
  left: -15px;
  top: -15px;
  z-index: -1;
}
.galfi_p::after{
  content: "";
  background: url("./Assets/pbottom.webp") no-repeat;
  width: 41px;
  height: 41px;
  position: absolute;
  right: 0px;
  bottom: -15px;
  z-index: -1;
}
.lore{
  background: url("./Assets/loreback.webp") no-repeat;
  /* background-size: 100% 101%; */
  width: 100%;
  background-size: cover;
  background-position: center;
}
.about_galfi{
  background: url("./Assets/aboutgalfi.webp") no-repeat;
  /* background-size: 100% 100%; */
  width: 100%;
  background-size: cover;
  background-position: center;
}
.planets_back{
  background: url("./Assets/planetback.webp") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  /* background-size: cover; */
  /* min-height: 750px; */
}
.gallery{
  background: url("./Assets/galleryback.webp") no-repeat;
  /* background-size: 100% 100%; */
  width: 100%;
  background-size: cover;
  background-position: center;
}
.astronaut{
  /* height: 100vh; */
  width: 100%;
  max-width: 670px;
}


/* team */

.medialogo:hover{
  /* filter: drop-shadow(2px 4px 6px #00dffc2b); */
  transform: scale(1.1);
  transition: 0.5s;
}

.our-team {
  padding: 30px 0 40px;
  margin-bottom: 30px;
  /* background-color: #000; */
  background-color: #004b5761;
  border-radius: 2px;
  text-align: center;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 10px #00def914;
}

.our-team .picture {
  display: inline-block;
  height: 160px;
  width: 160px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: var(--themeclr);
  position: absolute;
  /* bottom: 135%; */
  bottom: 147%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}
.our-team .name{
  font-size: 26px;
}
.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--themeclr);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  /* box-shadow: 0 0 0 14px #f7f5ec; */
  box-shadow: 0 0 0 11px #000;
  transform: scale(0.8);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: var(--themeclr);
  text-transform: capitalize;
}

@media screen and (min-width:1400px) and (max-width:1699px) {
  .our-team .title {
    font-size: 14px;
  }
  .our-team .name {
    font-size: 21px;
  }
}

@media screen and (min-width:1200px) and (max-width:1399px) {
  .our-team .title {
    font-size: 14px;
  }
  .our-team .name {
    font-size: 23px;
  }
}
@media screen and (min-width:1200px) {
    .medialogo {
      width: 220px;
      min-width: 220px;
      /* height: 80px; */
      /* min-height: 80px; */
      transition: 0.5s;
  }
}
@media screen and (min-width:1400px) {
  .medialogo{ 
    width: 260px;
    min-width: 260px;
    /* height: 90px; */
    /* min-height: 90px; */
     transition: 0.5s;
    }
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .our-team .title {
    font-size: 13px;
  }
  .our-team .name {
    font-size: 20px;
  }
  .medialogo{ 
    width: 250px;
    min-width: 250px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
  .our-team .title {
    font-size: 15px;
  }
  .our-team .name {
    font-size: 22px;
  }
}
/* .our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #1369ce;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social li a:hover {
  color: #1369ce;
  background-color: #f7f5ec;
} */


/* team end */



.profilecard{
  background: url("./Assets/backcard.webp") no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 100%;
 }
 .teamfullimg{
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
 }
 .teamimg{
  height: 320px;
  min-height: 320px;
  width: 320px;
  min-width: 320px;
 }
.backcard{
 background: url("./Assets/backcard.webp") no-repeat;
 background-size: 100% 100%;
 width: 380px;
 min-width: 380px;
 height: 550px;
 min-height: 550px;
}
.backcard img{
  height: 400px;
  min-height: 400px;
  width: 320px;
  min-width: 320px;
  margin: 30px auto;
}
.fw-600{font-weight: 600;}
.blog{
  background: url("./Assets/blogback.webp") no-repeat;
  /* background-size: 100% 100%; */
  width: 100%;
  background-size: cover;
  background-position: center;
}
.blogcardback{
  background: url("./Assets/blogcardback.webp") no-repeat;
  background-size: 100% 100%;
  width: 100%;
}
strong{
  color: #fff !important;
}
.pathcut{
  clip-path: polygon(0 0, 96% 0, 100% 4%, 100% 100%, 4% 100%, 0 96%);
  overflow: hidden;
}
.blogimg{
  /* height: 540px;
  min-height: 540px; */
  /* height: 500px;
  min-height: 500px;
  object-fit: cover; */
  opacity: 0.5;
  transition: 0.5s;
  width: 100%;
}
.blogimg:hover, .blogimg1:hover{
  transition: 0.5s;
  transform: scale(1.1);
}
.blogcardback .blogtitle{
  position: absolute;
  z-index: 0;
  bottom: 8%;
  line-height: 35px;
}
.simplecard .blogtitle{
  position: absolute;
  z-index: 0;
  bottom: 16%;
  line-height: 34px;
}
.profileimg{
  width: 46px;
  height: 46px;
  object-fit: cover;
  border-radius: 50%;
}
.dateimg{
  position: absolute;
  z-index: 0;
  bottom: 5%;
}
.simplecard{
  background: url("./Assets/simplecard.webp") no-repeat;
  background-size: 100% 100%;
  width: 85%;
  margin: auto;
}
.pathcuts{
  clip-path: polygon(0 0, 95% 0, 100% 6%, 100% 100%, 6% 100%, 0 93%);
  overflow: hidden;
  text-align: center;
  padding: 2px;
}
.blogimg1{

  /* height: 370px;
  min-height: 370px; */
  opacity: 0.5;
  transition: 0.5s;    
  object-fit: cover;
  width: 100%;
}
.blog_height{
  height: 680px;
  overflow: auto;
}
.b_data{
  height:550px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newslatest_img{
  width: 100%;
  max-width: 900px;
  /* height: 500px;
  min-height: 500px; */
  object-fit: cover;

}
.cursor{
  cursor: pointer;
}
.path_cutz{
  clip-path: polygon(0 0, 96% 0, 100% 4%, 100% 100%, 4% 100%, 0 96%);
}
.modal-content{
  background: linear-gradient(180deg, #232323 0%, #000000 100%) !important;
}
.modal-header{
  border-bottom: 1px solid transparent !important;
}
.btn-close{
  filter: invert(1);
  opacity: 1 !important;
}
.marquee_whole{
  margin: 0px 40px;
}
.blogcard{
  background: url("./Assets/blogcard2.webp") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 7px 12px 8px 8px;
  transition: all .3s ease;
  min-height: 370px;
}
.blogcard:hover{
  transform: translateY(-10px);
}
.blogcard:hover .blogcardimg{
  transform: scale(1.03);
}
.blogcard h6{
  line-height: 26px;
  min-height: 68px;
}
.blog .owl-stage-outer{
  padding-top: 20px;
}
.blogcut{
  /* clip-path: polygon(54% 4%, 93% 4%, 100% 8%, 100% 33%, 100% 71%, 100% 100%, 0 100%, 0 5%, 7% 0, 45% 0); */
  clip-path: polygon(54% 4%, 93% 4%, 100% 14%, 100% 33%, 100% 71%, 100% 100%, 0 100%, 0 9%, 6% 0, 45% 0);
  overflow: hidden;
  margin-top: 2px;
}
.blogcardimg{
  /* height: 420px;
    min-height: 420px; */
    transition: all .3s ease;
    object-fit: cover;
}
.blog .owl-theme .owl-nav [class*='owl-']:hover{
  background-color: transparent;
}
.blog .owl-next{
  position: absolute;
  right: 0;
  top: 38%;
}
.blog .owl-prev{
  position: absolute;
  left: 0;
  top: 38%;
}
.blogcard:hover .blogcardimg{
  opacity: 0.5;
  transition: all .3s ease;
}
.blogcard .FaEye{
  display: none;
  transition: all .3s ease;
}
.blogcard:hover .FaEye{
  display: block;
  transition: all .3s ease;
}
.FaEye{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: var(--themeclr);
  font-size: 32px;
}
.media{
  background: url("./Assets/newsback.webp") no-repeat;
  /* background-size: 100% 100%; */
  width: 100%;
  background-size: cover;
  background-position: center;
}
.newsimg{
  filter: grayscale(1);
  /* height: 175px;
  min-height: 175px; */
  width: 310px;
  max-width: 310px;
  margin: auto;
  object-fit: cover;
}
.newsimg:hover{
  filter: unset;
}
.news{
  transition: 0.5s;
}
.news:hover .newsimg{
  transition: 0.5s;
  transform: scale(1.02);
}
.new_text{
  position: absolute;
  bottom: 2%;
  width: 300px;
  left: 2%;
}
.h_under{
  height: 800px;
  overflow-y: auto;
}
.newsdepth{
  position: absolute;
  left: 0;
  overflow-y: auto;
  z-index: 77;
  top: 0;
  background: #151515;
  width: 100%;
}
.socialmedia {
  display: flex;
  align-items: center;
  gap: 20px;
}
.socialmedia a img{
  width: 55px;
  height: 55px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
.socialmedia a img.twiticon {
  width: 42px;
  height: 42px;
}
.socialmedia a img.mediumicon {
width: 70px;
height: 70px;
}
.closebtn{
  color: #fff;
  font-size: 32px;
}
/* div[style="transform: translateY(-50%) translateX(-25%) scale(0.5); left: 0%; opacity: 0.25; z-index: 0;"]{
  transform: translateY(-50%) translateX(-25%) scale(0.8) !important;
  transition: 0.5s !important;
}
div[style="transform: translateY(-50%) translateX(-75%) scale(0.5); left: 100%; opacity: 0.25; z-index: 0;"]{
  transform: translateY(-50%) translateX(-75%) scale(0.8) !important;
  transition: 0.5s !important;
} */
 .m_top{
  margin-top: -16px;
 }
 .heading .dropdown{
  /* padding: 0px 28px 0px 28px; */
    padding: 18px 28px 0px 28px;
 }
 .mobdrops .btn{
  padding: 0px !important;
 }
 .btn_toggle.btn-success{
   background-color: transparent !important;
   border-color: transparent !important;
   box-shadow: unset !important;
 }
 .show>.btn-success.dropdown-toggle:focus{
  box-shadow: unset !important;
 }
 /* .heading .dropdown-toggle::after{
  display: none;
 } */
 .heading .drop1{
  background: url("./Assets/drop1.webp") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  /* background: #127481b0;
  border-color: #127481b0; */
  /* border-color: #00e0ff1f;
  background: #00e0ff5c; */
  background: #046372;
  border-color: #00e0ff1f;
  border-radius: 2px;
 }
 .heading .dropdown-menu a{
  padding: 10px 15px !important;
   text-align: left;
   background: unset;
 }
 .heading .dropdown-menu a:hover{
  color: var(--themeclr) !important;
 }

 /* karthick css */
.whole__chakra{
  width: 100%;
  height: 100%;
  background: url('./Assets/chakra_bg.webp');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow: hidden;
}
.galfi_chakraSec.h_set{
  min-height: 100% !important;
  height: 100% !important;
}
.chakra__blueTitle{
  color: var(--themeclr);
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  left: 0%;
  top: 40%;
  max-width: 150px;
  word-wrap: break-word;
  word-break: break-word;
}
.cloud__chakra{
  width: 95%;
  height: 95%;
  min-width: 95%;
  min-height: 95%;
  max-width: 95%;
  max-height: 95%;
  position: absolute;
  left: 2.5%;
  top: 2.5%;
}
.chakra__shield{
  width: 48%;
  height: 48%;
  min-width: 48%;
  min-height: 48%;
  max-width: 48%;
  max-height: 48%;
  position: absolute;
  left: 32%;
  top: 25%;
}
.chakra__shieldHolder{
  height: fit-content;
  width: fit-content;
  min-height: fit-content;
  min-width: fit-content;
  max-height: fit-content;
  max-width: fit-content;
}
.socialmedia a:hover{
  filter: drop-shadow(2px 4px 9px #00dffc61);
 }
.border__chakra{
  width: 500px;
  height: 500px;
  border: 1px solid var(--white);
  border-radius: 50%;
  transform: rotate(-75deg);
  left: 6%;
  top: 3%;
  transition: 1s linear all;
}
.border__chakra.chakra1{
  transform: rotate(-120deg);
}
.border__chakra.chakra2{
  transform: rotate(-165deg);
}
.border__chakra.chakra3{
  transform: rotate(-210deg);
}
.border__chakra.chakra4{
  transform: rotate(105deg);
}
.border__chakra.chakra5{
  transform: rotate(60deg);
}
.border__chakra.chakra6{
  transform: rotate(15deg);
}
.border__chakra.chakra7{
  transform: rotate(-30deg);
}
.tile {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transform-origin: 250px 250px;
  transition: transform 1s;
  cursor: pointer;
  z-index: 2;
}
.chakra__descript{
  font-size: 15px;
  line-height: 26px;
}
.border__chakra .chakra__tileImg{
  display: block;
  transition: all 2s linear;
}
.tile:nth-child(1) { transform: rotate(calc(360deg / 8 * var(--i))) translate(215%, -45px); }
.tile:nth-child(2) { transform: rotate(calc(360deg / 8 * var(--i))) translate(215%, -45px); }
.tile:nth-child(3) { transform: rotate(calc(360deg / 8 * var(--i))) translate(215%, -45px); }
.tile:nth-child(4) { transform: rotate(calc(360deg / 8 * var(--i))) translate(215%, -45px); }
.tile:nth-child(5) { transform: rotate(calc(360deg / 8 * var(--i))) translate(215%, -45px); }
.tile:nth-child(6) { transform: rotate(calc(360deg / 8 * var(--i))) translate(215%, -45px); }
.tile:nth-child(7) { transform: rotate(calc(360deg / 8 * var(--i))) translate(215%, -45px); }
.tile:nth-child(8) { transform: rotate(calc(360deg / 8 * var(--i))) translate(215%, -45px); }

.tile:nth-child(1) .chakra__tileImg{
  transform: rotate(75deg);
}
.tile:nth-child(2) .chakra__tileImg{
  transform: rotate(30deg);
}
.tile:nth-child(3) .chakra__tileImg{
  transform: rotate(-15deg);
}
.tile:nth-child(4) .chakra__tileImg{
  transform: rotate(-60deg);
}
.tile:nth-child(5) .chakra__tileImg{
  transform: rotate(-105deg);
}
.tile:nth-child(6) .chakra__tileImg{
  transform: rotate(-150deg);
}
.tile:nth-child(7) .chakra__tileImg{
  transform: rotate(-195deg);
}
.tile:nth-child(8) .chakra__tileImg{
  transform: rotate(120deg);
}

.border__chakra.chakra1 .tile:nth-child(1) .chakra__tileImg{
  transform: rotate(120deg);
  transition-delay: .5s;
}
.border__chakra.chakra1 .tile:nth-child(2) .chakra__tileImg{
  transform: rotate(75deg);
  transition-delay: .5s;
}
.border__chakra.chakra1 .tile:nth-child(3) .chakra__tileImg{
  transform: rotate(30deg);
  transition-delay: .5s;
}
.border__chakra.chakra1 .tile:nth-child(4) .chakra__tileImg{
  transform: rotate(-15deg);
  transition-delay: .5s;
}
.border__chakra.chakra1 .tile:nth-child(5) .chakra__tileImg{
  transform: rotate(-60deg);
  transition-delay: .5s;
}
.border__chakra.chakra1 .tile:nth-child(6) .chakra__tileImg{
  transform: rotate(-105deg);
  transition-delay: .5s;
}
.border__chakra.chakra1 .tile:nth-child(7) .chakra__tileImg{
  transform: rotate(-150deg);
  transition-delay: .5s;
}
.border__chakra.chakra1 .tile:nth-child(8) .chakra__tileImg{
  transform: rotate(-195deg);
  transition-delay: .5s;
}

.border__chakra.chakra2 .tile:nth-child(1) .chakra__tileImg{
  transform: rotate(165deg);
}
.border__chakra.chakra2 .tile:nth-child(2) .chakra__tileImg{
  transform: rotate(120deg);
}
.border__chakra.chakra2 .tile:nth-child(3) .chakra__tileImg{
  transform: rotate(75deg);
}
.border__chakra.chakra2 .tile:nth-child(4) .chakra__tileImg{
  transform: rotate(30deg);
}
.border__chakra.chakra2 .tile:nth-child(5) .chakra__tileImg{
  transform: rotate(-15deg);
}
.border__chakra.chakra2 .tile:nth-child(6) .chakra__tileImg{
  transform: rotate(-60deg);
}
.border__chakra.chakra2 .tile:nth-child(7) .chakra__tileImg{
  transform: rotate(-105deg);
}
.border__chakra.chakra2 .tile:nth-child(8) .chakra__tileImg{
  transform: rotate(-150deg);
}


.border__chakra.chakra3 .tile:nth-child(1) .chakra__tileImg{
  transform: rotate(210deg);
}
.border__chakra.chakra3 .tile:nth-child(2) .chakra__tileImg{
  transform: rotate(165deg);
}
.border__chakra.chakra3 .tile:nth-child(3) .chakra__tileImg{
  transform: rotate(120deg);
}
.border__chakra.chakra3 .tile:nth-child(4) .chakra__tileImg{
  transform: rotate(75deg);
}
.border__chakra.chakra3 .tile:nth-child(5) .chakra__tileImg{
  transform: rotate(30deg);
}
.border__chakra.chakra3 .tile:nth-child(6) .chakra__tileImg{
  transform: rotate(-15deg);
}
.border__chakra.chakra3 .tile:nth-child(7) .chakra__tileImg{
  transform: rotate(-60deg);
}
.border__chakra.chakra3 .tile:nth-child(8) .chakra__tileImg{
  transform: rotate(-105deg);
}

.border__chakra.chakra4 .tile:nth-child(1) .chakra__tileImg{
  transform: rotate(255deg);
}
.border__chakra.chakra4 .tile:nth-child(2) .chakra__tileImg{
  transform: rotate(210deg);
}
.border__chakra.chakra4 .tile:nth-child(3) .chakra__tileImg{
  transform: rotate(165deg);
}
.border__chakra.chakra4 .tile:nth-child(4) .chakra__tileImg{
  transform: rotate(120deg);
}
.border__chakra.chakra4 .tile:nth-child(5) .chakra__tileImg{
  transform: rotate(75deg);
}
.border__chakra.chakra4 .tile:nth-child(6) .chakra__tileImg{
  transform: rotate(30deg);
}
.border__chakra.chakra4 .tile:nth-child(7) .chakra__tileImg{
  transform: rotate(-15deg);
}
.border__chakra.chakra4 .tile:nth-child(8) .chakra__tileImg{
  transform: rotate(-60deg);
}

.border__chakra.chakra5 .tile:nth-child(1) .chakra__tileImg{
  transform: rotate(300deg);
}
.border__chakra.chakra5 .tile:nth-child(2) .chakra__tileImg{
  transform: rotate(255deg);
}
.border__chakra.chakra5 .tile:nth-child(3) .chakra__tileImg{
  transform: rotate(210deg);
}
.border__chakra.chakra5 .tile:nth-child(4) .chakra__tileImg{
  transform: rotate(165deg);
}
.border__chakra.chakra5 .tile:nth-child(5) .chakra__tileImg{
  transform: rotate(120deg);
}
.border__chakra.chakra5 .tile:nth-child(6) .chakra__tileImg{
  transform: rotate(75deg);
}
.border__chakra.chakra5 .tile:nth-child(7) .chakra__tileImg{
  transform: rotate(30deg);
}
.border__chakra.chakra5 .tile:nth-child(8) .chakra__tileImg{
  transform: rotate(-15deg);
}


.border__chakra.chakra6 .tile:nth-child(1) .chakra__tileImg{
  transform: rotate(345deg);
}
.border__chakra.chakra6 .tile:nth-child(2) .chakra__tileImg{
  transform: rotate(300deg);
}
.border__chakra.chakra6 .tile:nth-child(3) .chakra__tileImg{
  transform: rotate(255deg);
}
.border__chakra.chakra6 .tile:nth-child(4) .chakra__tileImg{
  transform: rotate(210deg);
}
.border__chakra.chakra6 .tile:nth-child(5) .chakra__tileImg{
  transform: rotate(165deg);
}
.border__chakra.chakra6 .tile:nth-child(6) .chakra__tileImg{
  transform: rotate(120deg);
}
.border__chakra.chakra6 .tile:nth-child(7) .chakra__tileImg{
  transform: rotate(75deg);
}
.border__chakra.chakra6 .tile:nth-child(8) .chakra__tileImg{
  transform: rotate(30deg);
}

.border__chakra.chakra7 .tile:nth-child(1) .chakra__tileImg{
  transform: rotate(30deg);
}
.border__chakra.chakra7 .tile:nth-child(2) .chakra__tileImg{
  transform: rotate(345deg);
}
.border__chakra.chakra7 .tile:nth-child(3) .chakra__tileImg{
  transform: rotate(300deg);
}
.border__chakra.chakra7 .tile:nth-child(4) .chakra__tileImg{
  transform: rotate(255deg);
}
.border__chakra.chakra7 .tile:nth-child(5) .chakra__tileImg{
  transform: rotate(210deg);
}
.border__chakra.chakra7 .tile:nth-child(6) .chakra__tileImg{
  transform: rotate(165deg);
}
.border__chakra.chakra7 .tile:nth-child(7) .chakra__tileImg{
  transform: rotate(120deg);
}
.border__chakra.chakra7 .tile:nth-child(8) .chakra__tileImg{
  transform: rotate(75deg);
}

 /* end of karthick css */

 /* media query */
@media screen and (min-width:1800px) {
  .react-player{
    max-width: 75% !important;
    margin: auto !important;
  }
  .blogimg{
    /* height: 630px;
    min-height: 630px; */
    /* height:550px;
    min-height:550px;
    object-fit: cover; */
    object-position: center;
    width: 100%;
  }
  .blog_height{
    height: 680px;
    overflow: auto;
  }
  .carousel_width{
    width: 50%;
  }
  .h_set{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width:1600px) {
  .heading a{
    font-size: 16px;
    padding: 42px 30px 26px 26px !important;
  } 
  .custom_contain.head_contain {
    max-width: 96%;
  }
}

@media screen and (min-width:1400px) and  (max-width:1599px) {
  .heading a{
    font-size: 15px;
    padding: 42px 29px 26px 25px !important;
  } 
  .custom_contain.head_contain {
    max-width: 98%;
  }
}

@media screen and (min-width:1200px) {
.big_font{
  font-size: 50px !important;
}
.planets_back{
  min-height: 750px;
}
.galfi_top{
   font-size: 2rem !important; 
}
.playbtn,.blog_head,.role{
  font-size: 1.5rem;
}
.galfi_text, .no_data, .galfi_roadmaptxt{
  font-size: 1.75rem !important;
}

}

.galfi_top{
  font-size: calc(1.325rem + .9vw);
}
.playbtn, .blog_head, .role{
  font-size: calc(1.275rem + .3vw);
}
.big_font{
  font-size: calc(1.375rem + 1.5vw);
}
.galfi_text, .no_data, .galfi_roadmaptxt{
font-size: calc(1.3rem + .6vw);
}
.blog_subtxt{
  font-size: 1.25rem;
}
.galfi_blog{
  font-size: 1rem;
}
.header .logo{
  width: 100%;
}

@media screen and (min-width:1200px) and  (max-width:1399px) {
  .heading a{
    font-size: 15px;
    padding: 42px 24px 26px 20px !important;
  } 
  .heading #dropdown-basic{
    font-size: 15px;
  }
}
@media screen and (max-width:1399px) {
.header .logo{
  /* max-width: 180px; */
  max-width: 160px;
}
.heading a{
  /* padding: 26px 26px 26px 26px; */
  padding: 42px 30px 26px 26px;
  
}
}

.heading .dropdown-menu a {
  display: flex;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid #7c7c7c96;
}
@media screen and (max-width:1199px) {
  .mobdrops .btn{
    padding: 0px !important;
  }
  .heading .dropdown {
    padding: 16px 28px 16px 28px;
}
  .heading .dropdown-menu a {
    display: flex;
    align-items: center;
    gap: 5px;
}
  .arrow_left {
    left: 0!important;
}
.arrow_right{
   right: 0px !important;
}
.carousel_width div[style="transform: translateY(-50%) translateX(-25%) scale(0.5); left: 0%; opacity: 0.25; z-index: 0;"] {
  transform: translateY(-50%) translateX(-25%) scale(0.5) !important;
  transition: 0.5s !important;
  filter: grayscale(1);
  
}
.carousel_width div[style="transform: translateY(-50%) translateX(-75%) scale(0.5); left: 100%; opacity: 0.25; z-index: 0;"]{
  transform: translateY(-50%) translateX(-75%) scale(0.5) !important;
  transition: 0.5s !important;
  filter: grayscale(1);
}
  .heading a:hover, .heading a.active{
    background: unset;
    color: var(--themeclr) !important;
  }
  .m_top{
    margin-top: 0px;
   }
  .blogimg1 {
    /* height: 250px;
    min-height: 250px; */
}
  .simplecard .blogtitle {
    bottom: 22%;
}
  .simplecard{
    width: 100%;
  }
  .carousel_width{
    width: 80%;
  }
  .heading a{
    padding: 16px 0px;
  }
  .heading a:hover{
    background: unset ;
    color: var(--themeclr);
  }
  .offcanvas-title .smalllogo{
    width: 160px;
  }
  .offcanvas-end{
    background-color: #000 !important;
  }
  .navbar-light .navbar-toggler{
    background-color: #fff !important;
    border-color: #fff !important;
  }
  .h_set{
    height: unset;
  }
  .p_top{
    padding-top: 110px;
  }
}

@media screen and (max-width:992px) and (max-width:1199px) {
 .copyright{
  font-size: 15px;
 }
}

@media screen and (max-width:991px) {
  .carousel_width{
    width: 90%;
  }
  .h_under,.blog_height{
    height: unset;
  }
}
@media screen and (max-width:767px) {
  .newsimg {
    width: 250px;
    min-width: 250px;
    margin: auto;
}
  .new_text {
    width: 210px;
}
.astronaut{
  height: unset;
}
}

.galfi_top{
  position: absolute;
  top: 70px;
  left: 40px;
}

@media screen and (max-width:575px) {
  .blogcardback .blogtitle {
    line-height: 28px;
    font-size: 16px;
}
.simplecard .blogtitle {
  line-height: 28px;
  font-size: 16px;
}
  .roadmap_section li {
    gap: 18px;
}
  .blogimg{
    /* height: 480px;
    min-height: 480px; */
  }
  .offcanvas-header{
    padding: 1rem 2rem 1rem 1rem !important;
  }
  .wraprev{
    flex-wrap: wrap-reverse;
  }
  .socialmedia a img.mediumicon {
    width: 68px;
    height: 68px;
}
.socialmedia a img.twiticon {
  width: 41px;
  height: 41px;
}
  .play_galfi {
    background-size: cover;
    width: 100%;
    background-position: center;
}
  .blogcardimg {
    /* height: 350px;
    min-height: 350px; */
}
  .playbtn{width: unset;min-width: unset;}
.backcard img {
  width: 300px;
  min-width: 300px;
}
.backcard {
  width: 310px;
  min-width: 310px;
}
.galfi_p::before{
  left: 0;
}
.new_text {
  width: 260px;
}
.socialmedia a img {
  width: 44px;
  height: 44px;
}
.newsimg {
  /* width: 310px;
  min-width: 310px; */
  width: 100%;
  min-width: 100%;
  margin: auto;
}
}
@media screen and (max-width:359px) {
  .newsimg {
    width: 260px;
    min-width: 260px;
}
}



/* end of media query */
.blogpage a{
  text-decoration: none;
}
.viewmore{
  color: #000;
  background: var(--themeclr);
  border: 1px solid var(--themeclr);
  border-radius: 25px;
  padding: 12px 30px;
}
.viewmore:hover{
  border: 1px solid var(--themeclr);
  background: transparent;
  color: var(--themeclr);
}

@media screen and (min-width:1800px) {
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
}



/* karthick media query */
@media(width >= 1400px){
  .copyright{
    font-size: 17px;
  }
  .chakra__shieldHolder{
    margin-top: 6.5%;
  }
  .header .logo {
    max-width: 186px;
}
  .alltext {
   font-size: 15px;
  }
  .point0 {
    left: -24%;
    top: 15%;
}
.h_set.h_set1{
  height: 100vh !important;
}
}
@media(width > 1400px){
  .chakra__blueTitle{
    left: 5%;
  }
  .alltext {
   font-size: 15px;
  }
  .point0 {
    left: -26%;
    top: 16%;
}
}
@media (1200px <= width < 1400px){
  .custom_contain.head_contain{
    max-width: 99%;
  }
  .chakra__shieldHolder{
    margin-top: 6.5%;
  }
  .arrow_left{
    left: -150px !important;
  }
  .arrow_right{
    right: -150px !important;
  }
  .h_set.h_set1{
    height: 100vh !important;
  }
  .heading .dropdown {
    padding: 16px 12px 0px 12px;
}
  .alltext {
    font-size: 14px;
   }
   .point4 {
    right: -56%;
    bottom: 38%;
}
.point3 {
  left: 94%;
}
  .point0 {
    left: -24%;
    top: 14%;
}
  .border__chakra {
    width: 450px;
    height: 450px;
  }
  .tile {
    width: 90px;
    height: 90px;
    transform-origin: 230px 225px;
  }
}
@media (768px <= width < 992px){
  .point3 {
    left: 93%;
}
  .border__chakra {
    width: 450px;
    height: 450px;
  }
  .tile {
    width: 90px;
    height: 90px;
    transform-origin: 230px 225px;
  }
  .point4 {
    right: -56%;
    bottom: 38%;
}
}

@media (576px <= width < 768px){
  .border__chakra {
    width: 350px;
    height: 350px;
  }
  .tile {
    width: 70px;
    height: 70px;
    transform-origin: 175px 165px;
  }
  .whole__chakra{
    min-height: 75vh;
  }
  .chakra__blueTitle{
    top: 29%;
  }

  /* hari */
.point0 {
  top: 13%;
}
.point1 {
  left: 12%;
  top: -8%;
}
.point3 {
  left: 83%;
  top: 10%;
}
.point6 {
  bottom: -13%;
}
.point4 {
  right: -60%;
  bottom: 41%;
}
.point7 {
  bottom: 13%;
  left: -12%;
}
 /* hari */
}
@media (450px <= width < 576px){

/* hari */
.point0 {
  left: -13%;
  top: -5%;
}
.point1 {
  left: 25%;
  top: -13%;
}
.point2 {
  left: 70%;
  top: -15%;
}
.point3 {
  left: 90%;
  top: 2%;
}
.point4 {
  right: -24%;
  bottom: 46%;
}
.point5 {
  bottom: -3%;
  left: 85%;
}
.point6 {
  bottom: -16%;
  left: 15%;
}
.point7 {
  bottom: 10%;
  left: -12%;
}
.alltext{
  width: 100px;
  min-width: 100px;
}
/* hari end */


  .border__chakra {
    width: 300px;
    height: 300px;
    left: 0;
  }
  .tile {
    width: 60px;
    height: 60px;
    transform-origin: 150px 150px;
  }
  .whole__chakra{
    min-height: 51vh;
    background: transparent;  
  }
  .chakra__blueTitle{
    top: 10%;
  }
  .tile:nth-child(1) {
    transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(2) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(3) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(4) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(5) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(6) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(7) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(8) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.chakra__shield {
  left: 26%;
  top: 25%;
}
}

@media ( width < 450px){

/* hari */
.point0 {
  left: -23%;
  top: -18%;
}
.point1 {
  left: 30%;
  top: -19%;
}
.point2 {
  left: 75%;
  top: -20%;
}
.point3 {
  left: 95%;
  top: 4%;
}
.point4 {
  right: -48%;
  bottom: 35%;
}
.point5 {
  bottom: -3%;
  left: 80%;
}
.point6 {
  bottom: -16%;
  left: 15%;
}
.point7 {
  bottom: 12%;
  left: -28%;
}
.alltext{
  width:99px;
  min-width:99px;
  font-size: 12px;
}
/* hari add */


  .border__chakra {
    width: 220px;
    height: 220px;
    left: 0;
  }
  .tile {
    width: 50px;
    height: 50px;
    transform-origin: 109px 107px;
  }
  .whole__chakra{
    /* min-height: 55vh; */
    min-height: 50vh;
    background: transparent;  
  }
  .chakra__blueTitle{
    top: 10%;
  }
  .tile:nth-child(1) {
    transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(2) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(3) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(4) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(5) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(6) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(7) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.tile:nth-child(8) {
  transform: rotate(calc(360deg / 8* var(--i))) translate(204%, -30px);
}
.chakra__shield {
  left: 26%;
  top: 25%;
}
}

@media ( width < 359px){
  /* hari */
.point0 {
  left: -20%;
  top: -18%;
}
.point1 {
  left: 30%;
  top: -19%;
}
.point2 {
  left: 70%;
  top: -23%;
}
.point3 {
  left: 89%;
  top: 3%;
}
.point4 {
  right: -32%;
  bottom: 40%;
}
.point5 {
  bottom: -3%;
  left: 78%;
}
.point6 {
  bottom: -16%;
  left: 15%;
}
.point7 {
  bottom: 15%;
  left: -10%;
}
.alltext{
  width:99px;
  min-width:99px;
  font-size: 12px;
}
/* hari add */

}


/* end of karthick media query */
